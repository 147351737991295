<template>
    <div>
        <div
            v-if="!isLoaded"
            class="custom-loader container flex justify-content-center align-items-center"
            style="margin-top: 25%"
        />

        <div v-if="isLoaded" class="container d-flex flex-column is-warning">
            <!--      Campaign select step-->
            <b-steps
                v-model="activeStep"
                :has-navigation="false"
                size="is-large"
                mobile-mode="compact"
                :clickable="false"
            >
                <b-step-item
                    v-if="isCompanyCompleted === false"
                    label="Informations"
                    icon="fas fa-bullhorn"
                    :clickable="false"
                >
                    <div v-if="isLoaded" class="container profil">
                        <AccountAddress :user-profile="user" @saved="setStep(activeStep + 1)">
                            <template v-slot:title> Complétez vos informations personnelles</template>
                        </AccountAddress>
                    </div>
                </b-step-item>

                <b-step-item v-if="hasActiveCampaign" label="Type Achat" icon=' class="fas fa-question'>
                    <div class="container rounded shadow-sm bg-white p-3 d-flex flex-column fh-tunnel">
                        <div class="tile is-ancestor">
                            <nl-tile
                                title=" Accompagnement"
                                sub-title="Choisir un devis à régler"
                                style-class="is-info"
                                icon="fas fa-life-ring"
                                height-class="fh-300"
                                :greyed="
                                    (infos.accompagnementId || infos.accompagnementId === '') && buytype === 'credits'
                                "
                            >
                                <div class="d-flex">
                                    <b-select
                                        v-model="infos.accompagnementId"
                                        class="w-100"
                                        size="is-medium"
                                        expanded
                                        placeholder="Choisir un devis"
                                    >
                                        <option value="" selected="selected" disabled>
                                            {{ $t('paymentTunnel.selectCampaign') }}
                                        </option>
                                        <option
                                            v-for="i in invoices"
                                            :key="i.id"
                                            :value="JSON.parse(i.infos).accompagnementId"
                                        >
                                            {{ i.url }} -
                                            {{
                                                new Date(i.formatted_infos.period).toLocaleDateString('fr-fr', {
                                                    month: 'long',
                                                    year: 'numeric'
                                                })
                                            }}
                                        </option>
                                    </b-select>

                                    <a
                                        v-if="infos.accompagnementId && infos.accompagnementId !== ''"
                                        class="btn btn-warning mx-3 my-0 pt-2"
                                        @click="getPDF(quotationId, 'quote')"
                                    >
                                        <i class="fas fa-file-invoice"/>
                                    </a>
                                </div>
                            </nl-tile>

                            <nl-tile
                                :title="$t('achat-credits.title')"
                                :sub-title="$t('achat-credits.subtitle')"
                                style-class="is-yellow"
                                icon="fas fa-euro-sign"
                                :greyed="infos.accompagnementId && infos.accompagnementId !== ''"
                                height-class="fh-300"
                                @click="resetCampaign()"
                            >
                                <b-button
                                    expanded
                                    size="is-medium"
                                    icon-left="fas fa-euro-sign"
                                    @click="resetCampaign()"
                                >
                                    Acheter des crédits
                                </b-button>
                            </nl-tile>
                        </div>
                    </div>

                    <b-button
                        v-if="infos.accompagnementId && infos.accompagnementId !== ''"
                        type="is-warning"
                        expanded
                        icon-right="fas fa-arrow-right"
                        icon-left="fas fa-life-ring"
                        size="is-medium"
                        @click="setStep(1)"
                    >
                        {{ $t('paymentTunnel.continue') }}
                    </b-button>

                    <b-button
                        v-if="(infos.accompagnementId || infos.accompagnementId === '') && buytype === 'credits'"
                        type="is-warning"
                        expanded
                        icon-right="fas fa-arrow-right"
                        icon-left="fas fa-euro-sign"
                        size="is-medium"
                        @click="setStep(1)"
                    >
                        {{ $t('paymentTunnel.continue') }}
                    </b-button>
                </b-step-item>
                <!--     END: Campaign selec stept-->

                <!-- Amount form input step -->
                <b-step-item label="Montant" icon="fas fa-euro-sign">
                    <div class="container rounded shadow-sm bg-white p-3 d-flex flex-column fh-tunnel">
                        <h2 class="ml-auto mr-auto mt-6">
                            {{ $t('achat-credits.wantedamount') }}
                        </h2>

                        <div class="container d-flex s-flex-column mt-1">

                            <div class="container">
                                <div class="flex ml-auto mr-auto w-50">

                                    <div class="text-center bg-warning p-3 mb-4">
                                        Nous n'acceptons plus les thématiques : adulte, jeux d'argent, poker, casino,
                                        paris sportifs, politique, religion, e-cigarette, CBD, alcool, armes... et
                                        toutes les thématiques interdites aux moins de 18 ans.
                                    </div>

                                    <div class="flex">
                                        <b-field :label="$t('achat-credits.amount-title')" custom-class="is-medium">
                                            <b-numberinput
                                                v-model="amountHT"
                                                class="w-100"
                                                type="is-warning"
                                                controls-alignment="left"
                                                size="is-medium"
                                                step="1"
                                                :disabled="disabledPrice"
                                            />
                                            <p class="ml-2" style="font-size: 30px">€</p>
                                        </b-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p v-if="amountHT < 50" class="alert alert-danger">
                            {{ $t('achat-credits.minimumamount') }} 50€
                        </p>
                    </div>

                    <p v-if="amountHT >= 50">
                        <b-button
                            icon-right="fas fa-arrow-right"
                            type="is-warning"
                            expanded
                            size="is-medium"
                            @click="setStep(activeStep + 1)"
                        >
                            {{ $t('paymentTunnel.continue') }}
                        </b-button>
                    </p>
                </b-step-item>

                <!-- END : Amount form input step -->

                <!--    Recap step-->
                <b-step-item label="Récapitulatif" icon="fas fa-glasses">
                    <div class="container rounded shadow-sm bg-white p-3 d-flex flex-column fh-tunnel">
                        <h3 class="w-100 text-center">
                            {{ $t('achat-credits.recap') }}
                        </h3>
                        <p class="w-100 text-center text-gray-400">
                            {{ $t('achat-credits.payment-methods') }}
                        </p>

                        <div v-if="amountHT >= 50" class="container d-flex align-items-start">
                            <div class="container pl-3 mt-3 w-100 d-flex flex-column align-items-center">
                                <table class="table table-striped">
                                    <tr>
                                        <td v-if="infos.accompagnementId && infos.accompagnementId !== ''" colspan="2">
                                            <h4>{{ $t('paymentTunnel.payCampaign') }}</h4>

                                            {{ computeCampaignSlug }}
                                            <a
                                                v-if="infos.accompagnementId && infos.accompagnementId !== ''"
                                                class="btn btn-warning mx-3"
                                                @click="getPDF(quotationId, 'quote')"
                                            >
                                                <i class="fas fa-file-invoice"/>
                                            </a>
                                        </td>

                                        <td v-else colspan="2">
                                            <h4>{{ $t('paymentTunnel.buyCredits') }} : {{ amountHT }}€ (HT)</h4>
                                        </td>
                                    </tr>
                                    <tr class="table-primary">
                                        <td>{{ $t('achat-credits.designation-title') }}</td>
                                        <td>{{ $t('achat-credits.amount-title') }}</td>
                                    </tr>
                                    <tr>
                                        <td>HT</td>
                                        <td>{{ amountHT }}€</td>
                                    </tr>
                                    <tr>
                                        <td>TVA - {{ tva }}%</td>
                                        <td>{{ computeTVAAmount }}€</td>
                                    </tr>
                                    <tr class="table-secondary">
                                        <td>TTC</td>
                                        <td>{{ computeTTC }}€</td>
                                    </tr>
                                </table>
                            </div>
                            <div class="container w-50 d-flex flex-column align-items-center fh-tunnel">
                                <div v-if="displayCoupons" class="d-flex flex-column align-items-center w">
                                    <p class="mt-3 mb-0 text-center">
                                        {{ $t('achat-credits.promocodeenter') }}
                                    </p>
                                    <div class="container rounded">
                                        <div class="d-flex w-100 align-items-center mt-2 justify-content-center">
                                            <input v-model="coupon.code" type="text" class="form-control w-75"/>
                                            <a href="#" class="btn btn-secondary my-1 ml-2" @click="checkCoupon()">{{
                                                    $t('achat-credits.apply')
                                                }}</a>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-if="coupon.value > 0 && coupon.id != null"
                                    class="alert alert-success text-center w-75"
                                >
                                    {{ $t('achat-credits.promocodeuse') }} {{ coupon.code }} ({{
                                        coupon.value
                                    }}{{ $t('achat-credits.creditgain') }})
                                </div>
                                <div v-if="!!coupon.invalide" class="alert alert-danger text-center w-75">
                                    {{ $t('achat-credits.promocodenotvalid') }}
                                </div>
                                <p class="mb-1 mt-3">
                                    {{ $t('achat-credits.additional-info') }}
                                </p>
                                <textarea
                                    v-model="moreInfos"
                                    type="text"
                                    :placeholder="$t('achat-credits.useful-reference')"
                                    class="form-control w-75 mt-2"
                                />
                            </div>
                        </div>
                        <!--<form
                            id="paymentForm"
                            action="https://sogecommerce.societegenerale.eu/vads-payment/"
                            method="POST"
                            class="text-center"
                        >
                            <input type="hidden" name="signature" :value="computeSignature" />
                            <input type="hidden" name="vads_action_mode" :value="vadsParams.action_mode" />
                            <input type="hidden" name="vads_amount" :value="String(computeTTCForSOGE)" />
                            <input type="hidden" name="vads_ctx_mode" :value="vadsParams.ctx_mode" />
                            <input type="hidden" name="vads_currency" :value="vadsParams.currency" />
                            <input type="hidden" name="vads_page_action" :value="vadsParams.page_action" />
                            <input type="hidden" name="vads_payment_config" :value="vadsParams.payment_config" />
                            <input type="hidden" name="vads_site_id" :value="vadsParams.site_id" />
                            <input
                                type="hidden"
                                name="vads_trans_date"
                                :value="new Date().toISOString().split('.')[0].replace(/[^\d]/gi, '')"
                            />
                            <input type="hidden" name="vads_trans_id" :value="vadsParams.trans_id" />
                            <input type="hidden" name="vads_version" :value="vadsParams.version" />
                            <input type="hidden" name="vads_cust_email" :value="user.email" />
                            <input type="hidden" name="vads_cust_first_name" :value="user.firstname" />
                            <input type="hidden" name="vads_cust_last_name" :value="user.lastname" />
                            <input type="hidden" name="vads_return_mode" :value="vadsParams.return_mode" />
                            <input type="hidden" name="vads_order_id" :value="vadsParams.order_id" />
                        </form>-->
                    </div>
                    <b-button
                        icon-right="fas fa-arrow-right"
                        type="is-warning"
                        expanded
                        size="is-medium"
                        @click="setStep(activeStep + 1)"
                    >
                        {{ $t('paymentTunnel.continue') }}
                    </b-button>
                </b-step-item>
                <!-- END: Recap Step -->

                <!-- START: Payment Step -->
                <b-step-item label="Paiement" icon="fas fa-credit-card">
                    <div class="container rounded shadow-sm bg-white p-5 d-flex flex-column">
                        <div class="tile is-ancestor">
                            <nl-tile
                                title="Par CB"
                                sub-title="Paiement sécurisé"
                                style-class="is-link"
                                icon="fas fa-credit-card"
                                height-class="fh-400"
                            >
                                <b-button
                                    v-if="amountHT >= 50"
                                    class="btn btn-link mt-5 ml-auto mr-auto mb-5 py-4 hoverable bg-white"
                                    expanded
                                    icon-left="fas fa-credit-card"
                                    :loading="isLoading"
                                    @click="createStripe"
                                >

                                    Payer par CB
                                </b-button>

                            </nl-tile>

                            <nl-tile
                                title="Par Paypal"
                                sub-title="Paiement sécurisé"
                                style-class="is-info"
                                icon="fab fa-cc-paypal"
                                height-class="fh-400"
                            >
                                <div v-if="amountHT >= 50" class="mt-5 ml-auto mr-auto mb-5">
                                    <paypal-button :amount="amountHT"
                                                   :createNewOrderPaypal="createNewOrderPaypal"></paypal-button>
                                </div>

                                <!--<b-button
                                        v-if="amountHT >= 50"
                                        class="mt-5 ml-auto mr-auto mb-5"
                                        expanded
                                        icon-left="fas fa-credit-card"
                                        @click="createNewOrderPaypal()"
                                    >
                                        TEST Paypal
                                    </b-button>-->

                                <!--<b-button
                                    v-if="amountHT >= 50"
                                    class="mt-5 ml-auto mr-auto mb-5"
                                    expanded
                                    icon-left="fas fa-credit-card"
                                    @click="createNewOrder()"
                                >
                                    {{ $t('achat-credits.payment-card') }}
                                </b-button>-->
                            </nl-tile>

                            <nl-tile
                                title="Par virement"
                                sub-title="Délai Allongé"
                                style-class="is-yellow"
                                icon="fas fa-piggy-bank"
                                height-class="fh-400"
                            >
                                <b-button
                                    v-if="amountHT >= 50"
                                    class="btn btn-link mt-5 ml-auto mr-auto mb-5 py-4 hoverable bg-white"
                                    expanded
                                    icon-left="fas fa-credit-card"
                                    @click="bankModal = !bankModal"
                                >
                                    {{ $t('achat-credits.payment-transfer-preference') }}
                                </b-button>

                                <div v-if="bankModal" id="bankModalContainer" class="container">
                                    <div class="container d-flex"/>
                                    <div
                                        class="container form-check m-3 d-flex flex-column align-items-center fh-tunnel"
                                    >
                                        <div v-if="amountHT >= 50" class="d-flex">
                                            <input
                                                id="flexCheckDefault"
                                                v-model="bankButton"
                                                class="form-check-input"
                                                type="checkbox"
                                                value=""
                                            />
                                            <label class="form-check-label" for="flexCheckDefault">
                                                {{ $t('achat-credits.validation') }}
                                                <strong>{{ computeTTC }} TTC €.</strong>
                                                {{ $t('achat-credits.validation_02') }}
                                            </label>
                                        </div>

                                        <b-button
                                            v-if="bankButton && amountHT >= 50"
                                            expanded
                                            type="is-primary"
                                            icon-left="fas fa-coins"
                                            @click="createNewWire()"
                                        >
                                            {{ $t('achat-credits.payment-transfer') }}
                                        </b-button>
                                    </div>
                                </div>
                            </nl-tile>
                        </div>
                    </div>
                </b-step-item>
                <!-- END: Payment Step -->
            </b-steps>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import cryptoJS from 'crypto-js'
import AccountAddress from '@/components/Profil/AccountAddress.vue'
import Download from '@/utils/download'
import NlTile from '@/components/UI/NlTile'
import PaypalButton from "@/components/Invoices/PaypalButton.vue";

export default {
    title: 'credits.buy',
    components: {
        PaypalButton,
        NlTile,
        AccountAddress
    },
    data() {
        return {
            activeStep: 0,
            buytype: null,
            disabledPrice: false,
            labelPosition: 'bottom',
            appUrl: process.env.VUE_APP_URL,
            dataLoaded: false,
            isCompanyCompleted: false,
            isLoaded: false,
            isEnabled: true,
            bankModal: false,
            promoModal: false,
            bankButton: false,
            isLoading: false,
            amountHT: 50,
            tva: 0,
            order_id: null,
            invoices: null,
            codePromo: {
                name: '',
                value: 0
            },
            moreInfos: '',
            signature: null,
            id: 0,
            orders: null,
            vadsParams: {
                action_mode: 'INTERACTIVE',
                ctx_mode: process.env.VUE_APP_CTX_MODE,
                currency: '978',
                page_action: 'PAYMENT',
                payment_config: 'SINGLE',
                site_id: process.env.VUE_APP_SITE_ID,
                trans_id: '',
                version: 'V2',
                order_id: '',
                return_mode: 'GET'
            },
            sogeKey: process.env.VUE_APP_SOGE_KEY,
            infos: {
                accompagnementId: '',
                period: ''
            },
            quotationId: null,
            updateUserButton: false,
            coupon: {
                code: null,
                id: null,
                value: null,
                invalide: false
            }
        }
    },
    async mounted() {
        this.order_id = this.generateUUID()
    },
    async created() {
        await this.getUserInfos()
        await this.getData()

        this.isCompanyCompleted = !!(
            this.user &&
            this.user.ville != null &&
            this.user.address != null &&
            this.user.cp != null &&
            this.user.country != null
        )
        this.computeTVA()
        this.isLoaded = true
    },
    computed: {
        ...mapGetters('auth', {user: 'user'}),
        computeCampaignSlug() {
            let camp = this.invoices.find(x => x.accompagnement.id === this.infos.accompagnementId)
            return (
                camp.url +
                ' - ' +
                new Date(camp.formatted_infos.period).toLocaleDateString('fr-fr', {
                    month: 'long',
                    year: 'numeric'
                })
            )
        },
        computeSignature() {
            let message =
                this.vadsParams.action_mode +
                '+' +
                String(this.computeTTCForSOGE) +
                '+' +
                this.vadsParams.ctx_mode +
                '+' +
                this.vadsParams.currency +
                '+' +
                this.user.email +
                '+' +
                this.user.firstname +
                '+' +
                this.user.lastname +
                '+' +
                this.vadsParams.order_id +
                '+' +
                this.vadsParams.page_action +
                '+' +
                this.vadsParams.payment_config +
                '+' +
                this.vadsParams.return_mode +
                '+' +
                this.vadsParams.site_id +
                '+' +
                new Date().toISOString().split('.')[0].replace(/[^\d]/gi, '') +
                '+' +
                this.vadsParams.trans_id +
                '+' +
                this.vadsParams.version +
                '+' +
                this.sogeKey

            let hash = cryptoJS.HmacSHA256(message, this.sogeKey)

            return cryptoJS.enc.Base64.stringify(hash)
        },
        computeTTC() {
            let totalTva = this.computeTVAAmount
            return (this.computeHT() * 100 + totalTva * 100) / 100
        },
        computeTVAAmount() {
            return (this.amountHT * this.tva) / 100
        },
        computeTTCForSOGE() {
            return Math.round(this.computeTTC * 100)
        },
        hasActiveCampaign() {
            return this.invoices.length > 0
        },
        displayCoupons() {
            return true
        }
    },
    methods: {
        ...mapActions('auth', {getUserInfos: 'me'}),
        ...mapActions('auth', {getInfos: 'getInfos'}),
        ...mapActions('auth', {updateUserInfos: 'update'}),
        ...mapActions('invoices', {createOrder: 'create'}),
        ...mapActions('invoices', {createPaypal: 'createPaypal'}),
        ...mapActions('invoices', {
            list: 'list'
        }),
        goToStripe() {

        },
        getPDF(id, type) {
            this.axios
                .get('/orders/' + id + '/download/' + type)
                .then(response => {
                    Download.download(response, id + '-' + type + '.pdf')
                })
                .catch(error => {
                    console.log(error)
                })
        },
        resetCampaign() {
            this.infos.accompagnementId = ''
            this.amountHT = 0
            this.checkAccompagnement()
            this.buytype = 'credits'
            //this.setStep(1);
        },
        setStep(step) {
            this.activeStep = step
        },
        updateUser() {
            this.updateUserButton = true
            this.updateUserInfos(this.user).then(() => {
                this.$router.go()
            })
        },
        getData: async function () {
            await this.list().then(data => {
                this.invoices = data.data
            })
            this.invoices = await this.invoices.filter(
                e =>
                    e.category_order === 'accompagnement' &&
                    e.payment_status === 'pending' &&
                    e.accompagnement !== null
            )
            this.invoices.forEach(e => {
                if (e.accompagnement.url !== null) {
                    e.url = new URL(e.accompagnement.url).hostname
                }
            })
        },
        generateUUID() {
            return 'xxxxxx'.replace(/[xy]/g, function (c) {
                let r = (Math.random() * 16) | 0,
                    v = c === 'x' ? r : (r & 0x3) | 0x8
                return v.toString(16)
            })
        },
        checkAccompagnement() {
            if (this.infos.accompagnementId != null && this.infos.accompagnementId !== '') {
                this.amountHT = this.invoices.find(
                    e => JSON.parse(e.infos).accompagnementId === this.infos.accompagnementId
                ).price_ht
                this.id = this.invoices.find(
                    e => JSON.parse(e.infos).accompagnementId === this.infos.accompagnementId
                ).id
                this.infos.period = JSON.parse(
                    this.invoices.find(e => JSON.parse(e.infos).accompagnementId === this.infos.accompagnementId)
                        .infos
                ).period
                this.quotationId = this.invoices.find(
                    e => JSON.parse(e.infos).accompagnementId === this.infos.accompagnementId
                ).id
                // document.getElementById("inputPrice").disabled = true;
                this.disabledPrice = true
            } else if (this.infos.accompagnementId === '') {
                this.quotationId = null
                this.amountHT = 0
                this.id = null
                this.infos.period = null
                // document.getElementById("inputPrice").disabled = false;
                this.disabledPrice = false
            }
        },
        computeTVA() {
            switch (this.user.country) {
                case 'FR':
                    this.tva = 20
                    break
                case 'MQ':
                case 'RE':
                case 'GP':
                    this.tva = 8.5
                    break
                default:
                    this.tva = 0
                    break
            }
        },
        checkCoupon() {
            this.coupon.value = null
            this.coupon.id = null
            this.coupon.invalide = false
            this.axios
                .get(`/orders/invoices/check-coupon/${this.coupon.code}`)
                .then(res => {
                    this.coupon.value = res.data.coupon.reduction
                    this.coupon.id = res.data.coupon.id
                })
                .catch(() => {
                    this.coupon.invalide = true
                })
        },
        async createNewOrderPaypal() {
            let params = {
                id: Number(this.id),
                price_ht: Number(this.amountHT),
                price_ttc: this.computeTTC,
                tva_percent: this.tva,
                category_order: 'default',
                payment_id: this.order_id,
                type_order: 'paypal',
                infos: JSON.stringify(this.infos),
                coupon: this.coupon.code,
                more_infos: this.moreInfos
            }
            let data = await this.createPaypal(params)

            this.order_id = data.order_paypal.id

            return data.order_paypal.id;
        },
        // async createNewOrder() {
        //     let params = {
        //         id: Number(this.id),
        //         price_ht: Number(this.amountHT),
        //         price_ttc: this.computeTTC,
        //         tva_percent: this.tva,
        //         category_order: 'default',
        //         payment_id: this.vadsParams.trans_id,
        //         type_order: 'card',
        //         infos: JSON.stringify(this.infos),
        //         coupon: this.coupon.code,
        //         more_infos: this.moreInfos
        //     }
        //     let data = await this.createOrder(params)
        //     await (this.vadsParams.order_id = String(data.order.id))
        //
        //     document.getElementById('paymentForm').submit()
        // },
        async createNewWire() {
            let params = {
                id: Number(this.id),
                price_ht: Number(this.amountHT),
                price_ttc: this.computeTTC,
                tva_percent: this.tva,
                category_order: 'default',
                payment_id: this.order_id,
                type_order: 'wire',
                infos: JSON.stringify(this.infos),
                coupon: this.coupon.code,
                more_infos: this.moreInfos
            }
            await this.createOrder(params)
            void this.$router.push('/buy-credits/wire-confirmation')
        },
        createStripe() {
            this.isLoading = true;
            let params = {
                id: Number(this.id),
                price_ht: Number(this.amountHT),
                price_ttc: this.computeTTC,
                tva_percent: this.tva,
                category_order: 'default',
                payment_id: this.order_id,
                type_order: 'card',
                infos: JSON.stringify(this.infos),
                coupon: this.coupon.code,
                more_infos: this.moreInfos
            }

            this.createOrder(params).then((data) => {
                console.log(data)
                window.location.href = data.link
            }).catch((e) => {
                console.log(e)
            })
        },
        computeHT() {
            this.amountHT = (this.amountHT * 100) / 100

            return this.amountHT
        }
    },
    watch: {
        infos: {
            handler() {
                this.checkAccompagnement()
            },
            deep: true
        }
    }
}
</script>

<style lang="scss" scoped>
.is-pink {
    background-color: #533afd;
}

hr {
    border: 0;
    margin: 1rem;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 960px) {
    #paiment_header {
        flex-flow: column;
    }
}
</style>
