import { render, staticRenderFns } from "./AchatCreditsTunnel.vue?vue&type=template&id=2cbfd57e&scoped=true&"
import script from "./AchatCreditsTunnel.vue?vue&type=script&lang=js&"
export * from "./AchatCreditsTunnel.vue?vue&type=script&lang=js&"
import style0 from "./AchatCreditsTunnel.vue?vue&type=style&index=0&id=2cbfd57e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cbfd57e",
  null
  
)

export default component.exports